import { useCallback, useEffect, useState } from "react";
import { UseTabletSettings } from "../../hooks/useTabletSettings";
import { useModal } from "../../../../../hooks/contexts";

interface Props {
  tabletSettings: UseTabletSettings;
}

export default function useStepsController({ tabletSettings }: Props) {
  const Modal = useModal();

  const [currentStep, setCurrentStep] = useState(0);

  const {
    user,
    profile,
    queries: {
      saveUserGeneralTabletSettings: { isFetching: savingGeneralConfigs },
      saveUserAduboTabletSettings: { isFetching: savingAduboConfigs },
      saveUserSementeDefensivoTabletSettings: { isFetching: savingSementeDefensivoConfigs },
      saveUserFoliarTabletSettings: { isFetching: savingFoliarConfigs },
      userTabletSettingsQuery: { data: userTabletConfigs },
    },
    changes,
    resetUserForms,
  } = tabletSettings;

  const changeStep = useCallback(
    async (step: number) => {
      if (!userTabletConfigs) {
        Modal.alert("É necessário salvar as configurações gerais antes de acessar as outras abas de configuração.");
        return;
      }

      if (changes.isChangesDetected) {
        const confirm = await Modal.confirm("Você tem alterações que ainda não foram salvas.<br />Deseja continuar?");
        if (confirm) {
          changes.reset();
          resetUserForms();
          setCurrentStep(step);
        }
      } else {
        setCurrentStep(step);
      }
    },
    [Modal, changes, resetUserForms, userTabletConfigs]
  );

  const isSavingConfigs =
    savingGeneralConfigs || savingAduboConfigs || savingSementeDefensivoConfigs || savingFoliarConfigs;

  const saveSettingsByStep = useCallback(() => {
    switch (currentStep) {
      case 0:
        tabletSettings.forms.geralForm.handleSubmit((form) => {
          if (tabletSettings.queries.userTabletSettingsQuery.failureCount === 0)
            tabletSettings.queries.saveUserGeneralTabletSettings.fetch({
              id: tabletSettings.queries.userTabletSettingsQuery.data
                ? tabletSettings.queries.userTabletSettingsQuery.data.idConfiguracaoTablet
                : 0,
              idUsuario: user!.value.idUsuario,
              idPerfil: profile!.value.id,
              ipHost: form.host,
              porta: form.port,
              vendedores: form.sellers.map((i) => i.value.idVendedor),
              empresas: form.enterprises.map((i) => i.value.idEmpresa),
            });
        })();

        break;

      case 1:
        tabletSettings.forms.aduboForm.handleSubmit((form) => {
          tabletSettings.queries.saveUserAduboTabletSettings.fetch({
            id:
              tabletSettings.queries.userTabletSettingsQuery.data &&
              tabletSettings.queries.userTabletSettingsQuery.data.configuracaoTabletAdubo
                ? tabletSettings.queries.userTabletSettingsQuery.data.configuracaoTabletAdubo.idConfiguracaoTabletAdubo
                : 0,
            idConfiguracaoTablet: tabletSettings.queries.userTabletSettingsQuery.data!.idConfiguracaoTablet,
            idVendedorPadrao: form.defaultSeller!.value.idVendedor,
            idSupervisorPadrao: form.defaultSupervisor!.value.idSupervisor,
            idCondicaoPagamentoPadrao: form.defaultPaymentCondition?.value.idCondicaoPagamento ?? null,
            idOperacaoPadrao: form.defaultOperation?.value.id ?? null,
            idTabelaVigenciaPadrao: form.defaultValidityTable?.value.idVigencia ?? null,
            idEmpresaPadrao: form.defaultEnterprise!.value.idEmpresa,
            idDepositoPadrao: form.defaultDeposit!.value.idDeposito,
            verificaContratoCliente: form.verifyCustomerContract ? "S" : "N",
          });
        })();

        break;

      case 2:
        tabletSettings.forms.sementeDefensivoForm.handleSubmit((form) => {
          tabletSettings.queries.saveUserSementeDefensivoTabletSettings.fetch({
            id:
              tabletSettings.queries.userTabletSettingsQuery.data &&
              tabletSettings.queries.userTabletSettingsQuery.data.configuracaoTabletSementeDefensivo
                ? tabletSettings.queries.userTabletSettingsQuery.data.configuracaoTabletSementeDefensivo
                    .idConfiguracaoTabletSemDef
                : 0,
            idConfiguracaoTablet: tabletSettings.queries.userTabletSettingsQuery.data!.idConfiguracaoTablet,
            idVendedorPadrao: form.defaultSeller!.value.idVendedor,
            idSupervisorPadrao: form.defaultSupervisor!.value.idSupervisor,
            idOperacaoPadrao: form.defaultOperation?.value.id ?? null,
            idEmpresaPadrao: form.defaultEnterprise!.value.idEmpresa,
            idDepositoPadrao: form.defaultDeposit!.value.idDeposito,
            statusConfigSemDef: form.active ? "S" : "N",
          });
        })();

        break;

      case 3:
        tabletSettings.forms.foliarForm.handleSubmit((form) => {
          tabletSettings.queries.saveUserFoliarTabletSettings.fetch({
            id:
              tabletSettings.queries.userTabletSettingsQuery.data &&
              tabletSettings.queries.userTabletSettingsQuery.data.configuracaoTabletFoliar
                ? tabletSettings.queries.userTabletSettingsQuery.data.configuracaoTabletFoliar
                    .idConfiguracaoTabletFoliar
                : 0,
            idConfiguracaoTablet: tabletSettings.queries.userTabletSettingsQuery.data!.idConfiguracaoTablet,
            idVendedorPadrao: form.defaultSeller!.value.idVendedor,
            idSupervisorPadrao: form.defaultSupervisor!.value.idSupervisor,
            idOperacaoPadrao: form.defaultOperation?.value.id ?? null,
            idEmpresaPadrao: form.defaultEnterprise!.value.idEmpresa,
          });
        })();

        break;
    }
  }, [
    user,
    profile,
    currentStep,
    tabletSettings.forms.aduboForm,
    tabletSettings.forms.foliarForm,
    tabletSettings.forms.geralForm,
    tabletSettings.forms.sementeDefensivoForm,
    tabletSettings.queries.saveUserAduboTabletSettings,
    tabletSettings.queries.saveUserFoliarTabletSettings,
    tabletSettings.queries.saveUserGeneralTabletSettings,
    tabletSettings.queries.saveUserSementeDefensivoTabletSettings,
    tabletSettings.queries.userTabletSettingsQuery.data,
    tabletSettings.queries.userTabletSettingsQuery.failureCount,
  ]);

  useEffect(() => {
    setCurrentStep(0);
  }, [user]);

  return {
    currentStep,
    changeStep,
    saveSettingsByStep,
    isSavingConfigs,
  };
}

export type UseStepsController = ReturnType<typeof useStepsController>;
