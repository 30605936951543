function dateUtils(dateString: string) {
  const date = new Date(dateString);

  const toStartDayISOString = () => {
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
  };

  const toEndDayIsoString = () => {
    date.setUTCHours(23, 59, 59, 999);
    return date.toISOString();
  };

  return {
    toStartDayISOString,
    toEndDayIsoString,
  };
}

export default dateUtils;
