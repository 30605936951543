import { useCallback, useRef, useState } from "react";
import useAxiosInstance from "./useAxiosInstance";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useDebounce } from "../../hooks/async";

export namespace API {
  export enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
  }

  export interface Info<_TApiResponse, _TApiParams> {
    url: string;
    method: HttpMethod;
  }
}

interface FetchConfigs<TApiResponse, TSelectedData = TApiResponse> {
  onSuccess?: (data: TApiResponse) => void;
  onError?: (error: AxiosError<DefaultErrorResponse, any>) => void;
  select?: (data: TApiResponse) => TSelectedData;
  debounceTime?: number;
  axiosConfig?: AxiosRequestConfig;
}

export default function useFetch<TApiResponse, TApiParams, TSelectedData>(
  apiInfo: API.Info<TApiResponse, TApiParams>,
  fetchConfigs: FetchConfigs<TApiResponse, TSelectedData> & { select: (data: TApiResponse) => TSelectedData }
): ReturnTypeWithSelectedData<TApiParams, TApiResponse, TSelectedData>;

export default function useFetch<TApiResponse, TApiParams>(
  apiInfo: API.Info<TApiResponse, TApiParams>,
  fetchConfigs?: FetchConfigs<TApiResponse>
): ReturnTypeWithSelectedData<TApiParams, TApiResponse>;

export default function useFetch<TApiResponse, TApiParams, TSelectedData = TApiResponse>(
  apiInfo: API.Info<TApiResponse, TApiParams>,
  fetchConfigs: FetchConfigs<TApiResponse, TSelectedData> = {
    debounceTime: 500,
  }
) {
  const axios = useAxiosInstance();
  const abortController = useRef<AbortController | null>(null);
  const fetchCount = useRef(0);

  const [data, setData] = useState<TApiResponse | TSelectedData | null>(null);
  const [error, setError] = useState<AxiosError<DefaultErrorResponse, any> | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const axiosRequest = useCallback(
    async (params: TApiParams): Promise<AxiosResponse<TApiResponse, any>> => {
      abortController.current = new AbortController();

      const response = await axios({
        method: apiInfo.method,
        url: apiInfo.url,
        signal: abortController.current.signal,
        ...getCorrectParamsByHttpMethod(apiInfo.method, params),
        ...fetchConfigs?.axiosConfig,
      });

      abortController.current = null;

      return response;
    },
    [axios, apiInfo.method, apiInfo.url, fetchConfigs?.axiosConfig]
  );

  const fetch = useCallback(
    async (params: TApiParams) => {
      fetchCount.current += 1;
      setIsFetching(true);

      try {
        const response = await axiosRequest(params);
        setError(null);

        const selectedData = fetchConfigs.select ? fetchConfigs.select(response.data) : response.data;

        setData(selectedData);

        if (fetchConfigs?.onSuccess !== undefined) {
          fetchConfigs.onSuccess(response.data);
        }
      } catch (error: any) {
        setError(error);

        if (fetchConfigs?.onError !== undefined) {
          fetchConfigs.onError(error);
        }
      } finally {
        fetchCount.current -= 1;

        if (fetchCount.current === 0) {
          setIsFetching(false);
        }
      }
    },
    [axiosRequest, fetchConfigs]
  );

  const fetchDebounced = useDebounce(fetch, fetchConfigs.debounceTime);

  const abort = useCallback(() => {
    abortController.current?.abort();
  }, []);

  const clearData = () => {
    setData(null);
  };

  return { data, error, isFetching, isLoading: isFetching, fetch, fetchDebounced, axiosRequest, abort, clearData };
}

const getCorrectParamsByHttpMethod = (method: API.HttpMethod, params: unknown) => {
  if (method === "GET" || method === "DELETE") {
    return { params };
  } else {
    return { data: params };
  }
};

type ReturnTypeWithSelectedData<TParams, TResponse, TData = TResponse> = {
  data: TData | null;
  error: AxiosError<DefaultErrorResponse, any> | null;
  isFetching: boolean;
  fetch: (params: TParams) => Promise<void>;
  fetchDebounced: (params: TParams) => Promise<void>;
  axiosRequest: (params: TParams) => Promise<AxiosResponse<TResponse, any>>;
  abort: () => void;
  clearData: () => void;
};